import './App.css';
import Chat from './Chat';
import AboutJohnBaldoni from './components/AboutJohnBaldoni';
import Leadership from './components/Leadership';
// Import assets
import brandLogo from './influencerkit-logo.png';
import jb from './jb.png'

function App() {
  return (
    <div className="App">
      {/* Brand Logo */}
      <div className="brand-logo">
        <a href="https://influencerkit.ai">
          <img src={brandLogo} className="brandLogo" alt='brandLogo' />
        </a>
      </div>

      {/* Welcome Message */}
      <div className="text-container">
        <div className="top-half">Powers</div>
        <div className="bottom-half">Baldoni AI ChatBot</div>
      </div>
      <div className="image-container">
        <img src={jb} className='onlineVideoClasses' alt='onlineVideoClasses' />
        <div className="receive">
        <p> Receive John Baldoni's answers on leadership topics about which he has written, spoken and coached.
        <br/> These topics include purpose, grace, coaching, communications, conflict management, presence, power, authenticity.
        <br/> This AI ChatBot reflects John Baldoni's personality – his tone and his commitment to helping others. 
        <br/> If you do not receive the answer you are looking for, re-phrase your question and ask again. Doing so will only make my bot smarter. And you wiser.
        <br/> Click the ChatBot bubble icon at the bottom right corner to begin. </p>
      </div>
      </div>
    
      {/* Life and importance of John Baldoni */}
      <AboutJohnBaldoni />
      {/*Questions regarding the chatbot */}
      <Leadership />




      {/* Chat Widget */}
      <Chat className="chat-container" projectId="650280adbc473800076506df" />





    </div>
  );

}

export default App;