import React from 'react'

export default function AboutJohnBaldoni() {
  return (
    <div className='about'>
        <h2>About John Baldoni</h2>
        <p>
          John Baldoni is an internationally recognized leadership educator, executive coach and speaker. He's authored 16 leadership books, written over 1000 articles and produced over 150 coaching videos. This knowledge is synthesized in his personalized bot.
          Some of his most well-known books include:
        </p>
        <div className='titles'>
          <h3 className='title-head'>Grace Under Pressure: Leading Through Change and Crisis (2023) </h3>
          <p className='title-description'>Focuses on how leaders overcome adversity with courage, commitment and compassion.</p>
        </div>
        <div className='titles'>
          <h3 className='title-head'>Lead with Purpose: Giving Your Organization a Reason to Believe in Itself (2014)</h3>
          <p className='title-description'>Explores how leaders can create a sense of purpose and meaning to motivate employees.</p>
        </div>
        <div className='titles'>
          <h3 className='title-head'>Lead Your Boss: The Subtle Art of Managing Up (2010)</h3>
          <p className='title-description'>Focuses on strategies for effectively communicating with and influencing your supervisor.</p>
        </div>
        <div className='titles'>
          <h3 className='title-head'>Lead By Example: 50 Ways Great Leaders Inspire Results (2019)</h3>
          <p className='title-description'>Looks at leadership best practices and real-world examples.s</p>
        </div>
        <p className='book'>
          His books draw on his experience in business coaching and feature practical leadership advice, interviews with executives, and research into management trends. Baldoni's work is known for focusing on ethics, purpose-driven leadership and leading by example. He emphasizes servant leadership and creating positive corporate cultures. In total, his books have been published in 10 languages across the globe. He is a frequent keynote speaker and contributor to major media outlets on leadership topics.
        </p>
      </div>
  )
}
