
import { useState, useEffect } from 'react';

export default function Chat(props) {

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {

    const script = document.createElement('script');
    script.src = "https://kunkka0822.github.io/react-chat/packages/widget/dist/bundle.mjs";

    script.onload = () => {
      setScriptLoaded(true);
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }

  }, []);
  
  useEffect(() => {
    if (scriptLoaded) {
      initChat();
    }
  }, [scriptLoaded]);

  const initChat = () => {
    window.voiceflow.chat.load({
      verify: {
        projectID: props.projectId 
      },
      url: 'https://general-runtime.voiceflow.com',
      versionID: 'production' 
    });
  }

  return <div id="chat"/>;

}